/* Fonts inport */
/* @import url('https://fonts.googleapis.com/css?family=Raleway:400,700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css?family=Indie+Flower&display=swap'); */

/* Font awesome pro import */
@import url('https://pro.fontawesome.com/releases/v5.10.1/css/all.css');

:root {
  --primary-color: #00bcd4;
  --swiper-theme-color: #00bcd4 !important;
}

/* .font-source-code-pro {
  font-family: 'Source Code Pro', monospace;
}

.font-raleway {
  font-family: 'Raleway', sans-serif;
}

.font-poppins {
  font-family: 'Poppins', sans-serif;
}

.font-indie {
  font-family: 'Indie Flower', cursive;
} */

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  /* font-family: averta_stdregular, Helvetica Neue, Helvetica; */
}

/* For Section margin-top */
section {
  scroll-margin-top: 52px;
}

/* General utility class */
.center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* Used in Team.js */
.team-member-image {
  width: 128px !important;
  border-radius: 4px;
}

/* Used in Team.js */
.align-items-strech {
  /* display: flex !important; */
  align-items: stretch !important;
}

/* Used in Hero.js */
.partners-image {
  max-height: 96px !important;
  object-fit: contain;
}

/* Used in Contact.js */
.pt-0 {
  padding-top: 0px !important;
}

.footer {
  padding: 2rem 1.5rem;
}

.service-image {
  max-height: 320px;
  object-fit: contain;
}

/* Used in Press section For CARDS.... */
.press-image {
  object-fit: cover;
  border-radius: 8px 8px 0 0;
}

.card-class {
  display: flex;
  flex-grow: 1;
  /* 
  used flex-grow: 1 instead of bottom min-width
  min-width: 100%; 
  */
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch !important;
  border-radius: 8px;
  transition: all 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.card-class:hover {
  -ms-transform: scale(1.01);
  -webkit-transform: scale(1.01);
  transform: scale(1.01);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
}

.card-class > .card-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.card-class > .card-content > .button {
  align-self: flex-end;
  margin-top: auto;
}

/* Shapes for background */
section#home {
  /* background: url('./assets/images/background/hexagon.svg'); */
  /* background-repeat: no-repeat; */
}

@media only screen and (max-width: 768px) {
  section#home {
    background-size: 60%;
  }
}

section#home .hero-background-lottie {
  position: absolute;
  left: -30%;
  top: -10%;
  opacity: 1;
  transform: rotate(30deg);
}

/* Social icons colors */
.is-facebook {
  color: #3b5999 !important;
  /* box-shadow: 0 0 10px 0px #3b5999; */
}
.is-linkedin {
  color: #0077b5 !important;
}
.is-twitter {
  color: #55acee !important;
}

.is-whatsapp {
  color: #25d366 !important;
}
.is-instagram {
  color: #e4405f !important;
}

.is-facebook:hover {
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  border-radius: 4px;
  border: 1px solid #3b5999;
}
.is-linkedin:hover {
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  border-radius: 4px;
  border: 1px solid #0077b5;
}
.is-twitter:hover {
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  border-radius: 4px;
  border: 1px solid #55acee;
}
.is-whatsapp:hover {
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  border-radius: 4px;
  border: 1px solid #25d366;
}
.is-instagram:hover {
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  border-radius: 4px;
  border: 1px solid #e4405f;
}

@media screen and (min-width: 769px) {
  .section-heading::before {
    content: '';
    display: inline-block;
    height: 4px;
    width: 200px;
    position: relative;
    right: 20px;
    bottom: 8px;
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(253, 254, 254, 0.51) 9%,
      rgba(248, 252, 253, 0.53) 17%,
      rgba(239, 248, 250, 0.56) 24%,
      rgba(228, 243, 247, 0.6) 31%,
      rgba(215, 237, 243, 0.65) 37%,
      rgba(199, 231, 239, 0.7) 44%,
      rgba(180, 224, 234, 0.75) 50%,
      rgba(160, 217, 230, 0.8) 56%,
      rgba(138, 210, 225, 0.85) 63%,
      rgba(114, 203, 221, 0.9) 69%,
      rgba(88, 197, 218, 0.94) 76%,
      rgba(60, 192, 215, 0.97) 83%,
      rgba(31, 189, 213, 0.99) 91%,
      #00bcd4 100%
    );
    clip-path: polygon(100% 0, 0 50%, 100% 100%);
  }

  .section-heading::after {
    content: '';
    display: inline-block;
    height: 4px;
    width: 200px;
    position: relative;
    left: 20px;
    bottom: 8px;
    clip-path: polygon(100% 50%, 0 0, 0 100%);
    background-image: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(253, 254, 254, 0.51) 9%,
      rgba(248, 252, 253, 0.53) 17%,
      rgba(239, 248, 250, 0.56) 24%,
      rgba(228, 243, 247, 0.6) 31%,
      rgba(215, 237, 243, 0.65) 37%,
      rgba(199, 231, 239, 0.7) 44%,
      rgba(180, 224, 234, 0.75) 50%,
      rgba(160, 217, 230, 0.8) 56%,
      rgba(138, 210, 225, 0.85) 63%,
      rgba(114, 203, 221, 0.9) 69%,
      rgba(88, 197, 218, 0.94) 76%,
      rgba(60, 192, 215, 0.97) 83%,
      rgba(31, 189, 213, 0.99) 91%,
      #00bcd4 100%
    );
  }
}

@media screen and (max-width: 768px) {
  .section-heading::after {
    content: '';
    display: block;
    height: 2px;
    width: 96px;
    position: relative;
    top: 4px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #00bcd4;
  }

  .footer-columns {
    display: flex;
    flex-flow: column;
  }
  .footer-columns .copyright {
    order: 3;
  }
  .footer-columns .logo {
    order: 1;
  }
  .footer-columns .social {
    order: 2;
  }
  .cookieConsent {
    justify-content: center !important;
  }
}

.map-object-style {
  height: 100%;
  width: 100%;
  min-height: 300px;
}

/* Navbar Logo */
img.logo-navbar {
}

.is-responsive-icon {
  font-size: 8vw;
}

/* Change orintation on services sections */
@media only screen and (max-width: 768px) {
  .reverse-columns {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .is-responsive-icon {
    font-size: 16vw;
  }
}

.section-heading-text {
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}

.section-heading-text::before {
  /* font-family: 'Raleway', sans-serif; */
  font-weight: 700;
  font-size: 20vw;
  color: hsl(0, 0%, 96%);
  display: inline-block;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  content: attr(data-title);
  z-index: -999;
}

/* Text Colors */

.has-text-pink {
  color: #f368e0;
}
.has-text-orange {
  color: #ff9f43;
}
.has-text-red {
  color: #ee5353;
}
.has-text-aqua {
  color: #00d2d3;
}
.has-text-blue {
  color: #54a0ff;
}
.has-text-purple {
  color: #5f27cd;
}
.has-text-teal {
  color: #4ddbfb;
}
.has-text-green {
  color: #1cd1a1;
}
.has-text-black {
  color: #212f3e;
}

div.content.review {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
}

.demodiv {
  height: 200px;
  background-color: pink;
}

.neumorphism {
  margin: 2rem;
  box-shadow: 9px 9px 18px hsl(0, 0%, 86%), -9px -9px 18px hsl(0, 0%, 98%);
  /* box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02); */
}

/* .service-description {
  position: relative;
}
.service-description::before {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  content: '\f10d';
  color: lightgray;
  line-height: 1;
  font-size: 2rem;
  position: absolute;
  left: -30px;
  top: -30px;
} */

.boxy {
  display: inline-block;
  height: '200px';
  width: '200px';
  margin: '16px';
  background-color: '#00bcd4';
  object-fit: contain;
}

.brand-ticker {
  width: 100px;
  height: 100px;
  margin: 0 3rem 3rem 3rem;
  filter: grayscale(100%);
  opacity: 0.4;
  transition: 0.6s;
  object-fit: contain;
  cursor: pointer;
}

.brand-ticker:hover {
  filter: grayscale(0%);
  opacity: 1;
}

.pricing-tag {
  position: relative;
}
.pricing-tag::after {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  font-size: 30vw;
  content: '\f02c';
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -999;
  color: lightgray;
  opacity: 0.4;
}
