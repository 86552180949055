$pink: #f368e0;
$pink-invert: findColorInvert($pink);
$pink-light: findLightColor($pink);
$pink-dark: findDarkColor($pink);

$yellow: #feca57;
$yellow-invert: findColorInvert($yellow);
$yellow-light: findLightColor($yellow);
$yellow-dark: findDarkColor($yellow);

$teal: #4ddbfb;
$teal-invert: findColorInvert($teal);
$teal-light: findLightColor($teal);
$teal-dark: findDarkColor($teal);

$orange: #ff9f43;
$orange-invert: findColorInvert($orange);
$orange-light: findLightColor($orange);
$orange-dark: findDarkColor($orange);

$coral: #ff6b6b;
$coral-invert: findColorInvert($coral);
$coral-light: findLightColor($coral);
$coral-dark: findDarkColor($coral);

$red: #ee5353;
$red-invert: findColorInvert($red);
$red-light: findLightColor($red);
$red-dark: findDarkColor($red);

$aqua: #00d2d3;
$aqua-invert: findColorInvert($aqua);
$aqua-light: findLightColor($aqua);
$aqua-dark: findDarkColor($aqua);

$purple: #5f27cd;
$purple-invert: findColorInvert($purple);
$purple-light: findLightColor($purple);
$purple-dark: findDarkColor($purple);

$blacked: #212f3e;
$blacked-invert: findColorInvert($blacked);
$blacked-light: findLightColor($blacked);
$blacked-dark: findDarkColor($blacked);

$forest: #00a3a4;
$forest-invert: findColorInvert($forest);
$forest-light: findLightColor($forest);
$forest-dark: findDarkColor($forest);

$gray: #565869;
$gray-invert: findColorInvert($gray);
$gray-light: findLightColor($gray);
$gray-dark: findDarkColor($gray);

$blue: #569fff;
$blue-invert: findColorInvert($blue);
$blue-light: findLightColor($blue);
$blue-dark: findDarkColor($blue);

$green: #1cd1a1;
$green-invert: findColorInvert($green);
$green-light: findLightColor($green);
$green-dark: findDarkColor($green);

// 5. Add new color variables to the color map.
@import 'bulma/sass/utilities/derived-variables';

$addColors: (
  'coral': (
    $coral,
    $coral-invert,
    $coral-light,
    $coral-dark,
  ),
  'forest': (
    $forest,
    $forest-invert,
    $forest-light,
    $forest-dark,
  ),
  'pink': (
    $pink,
    $pink-invert,
    $pink-light,
    $pink-dark,
  ),
  'teal': (
    $teal,
    $teal-invert,
    $teal-light,
    $teal-dark,
  ),
  'orange': (
    $orange,
    $orange-invert,
    $orange-light,
    $orange-dark,
  ),
  'red': (
    $red,
    $red-invert,
    $red-light,
    $red-dark,
  ),
  'aqua': (
    $aqua,
    $aqua-invert,
    $aqua-light,
    $aqua-dark,
  ),
  'purple': (
    $purple,
    $purple-invert,
    $purple-light,
    $purple-dark,
  ),
  'blacked': (
    $blacked,
    $blacked-invert,
    $blacked-light,
    $blacked-dark,
  ),
  'yellow': (
    $yellow,
    $yellow-invert,
    $yellow-light,
    $yellow-dark,
  ),
  'gray': (
    $gray,
    $gray-invert,
    $gray-light,
    $gray-dark,
  ),
  'blue': (
    $blue,
    $blue-invert,
    $blue-light,
    $blue-dark,
  ),
  'green': (
    $green,
    $green-invert,
    $green-light,
    $green-dark,
  ),
);
$colors: map-merge($colors, $addColors);
